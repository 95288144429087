import { ArrowRight, Download, Redo } from '@/client/components/icons/ContinuIcons';
import { Assessment, UserAssessment } from '@/client/types/content/assessments/Assessment';
import { AssessmentScores, FileAnswer } from '@/client/types/content/assessments/AssessmentScores';
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Grid,
  GridItem,
  Image,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { Trans, useTranslation } from 'react-i18next';

import Certificate from '@/client/components/data-display/certificates/Certificate';
import ErrorAlert from '../../data-display/ErrorAlert';
import Loading from '@/client/components/media/Loading';
import { User } from '@/client/types/AuthConfig';
import VideoPlayer from '@/client/components/media/video/VideoPlayer';
import { useGetAssessmentScores } from '@/client/services/hooks/content/assessments/useGetAssessmentScores';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

interface AssessmentScoreProps {
  assessment: Assessment;
  userAssessment: UserAssessment;
  hasPassed: boolean;
  user: User;
  inTrack?: boolean;
  inJourney?: boolean;
  setRetakingAssessment: (retakingAssessment: boolean) => void;
  backToJourney?: () => void;
}

export default function AssessmentScore({
  assessment,
  userAssessment,
  hasPassed,
  user,
  inTrack,
  inJourney,
  setRetakingAssessment,
  backToJourney,
}: AssessmentScoreProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [viewingCertificate, setViewingCertificate] = useState(false);
  const latestScoreId = userAssessment.scores[userAssessment.scores.length - 1];

  const { data: assessmentScores, isLoading, isError } = useGetAssessmentScores(latestScoreId);

  const isPdf = (item: FileAnswer) => {
    if (!item || !item.link) {
      return false;
    }

    return item.link.slice(-3) === 'pdf';
  };

  const isImage = (item: FileAnswer) => {
    if (!item || !item.link) {
      return false;
    }

    return item.link.slice(-3) === 'png' || item.link.slice(-4) === 'jpeg';
  };

  const isOther = (item: FileAnswer) => !isPdf(item) && !isImage(item);

  const assessmentUsesFileAnswers = (
    item: FileAnswer | string,
    assessmentScores: AssessmentScores,
  ): item is FileAnswer => !!assessmentScores.assessment_when_scored.file_assessment;

  // const assessmentDoesNotUseFileAnswers = (
  //   item: FileAnswer | string,
  //   assessmentScores: AssessmentScores
  // ): item is string => !assessmentScores.assessment_when_scored.file_assessment;

  if (isLoading) return <Loading />;

  if (isError) return <ErrorAlert title={t('assessViewer.unableToGetScores')} />;

  const assessmentQuestions = assessmentScores.assessment_when_scored.questions;
  const userResponses = assessmentScores.graded_responses;
  const userAnswer0 = userResponses[assessment.questions[0].id].user_answer[0];

  return (
    <>
      <Box minHeight="100vh" backgroundColor="#f7f7f7" paddingBottom={12}>
        <Center
          minHeight="100vh"
          paddingY={20}
          background="linear-gradient(135deg, #00BCF1 0, #0080a4 100%)"
        >
          <VStack>
            {assessment.show_user_grade && (
              <Box textAlign="center">
                <Text fontSize="6xl" fontWeight="extrabold" color="white">
                  {Math.floor(assessmentScores.max_percentage)}%
                </Text>

                <Text fontSize="sm" color="white">
                  {t('assessViewer.your_total_score')}
                </Text>
              </Box>
            )}

            {hasPassed ? (
              <>
                <Box marginBottom={8} fontSize="100px">
                  &#128077;
                </Box>

                <Text as="h2" color="white">
                  {t('assessViewer.nice_work')}
                </Text>

                {assessment.certificate && (
                  <Button
                    onClick={() => {
                      setViewingCertificate(true);
                    }}
                  >
                    {t('assessmentViewer.show_certificate')}
                  </Button>
                )}
              </>
            ) : (
              <>
                <Box marginBottom={8} fontSize="100px">
                  &#x1F615;
                </Box>

                {!assessment.allow_retake ||
                  (userAssessment.scores.length - 1 === assessment.number_of_retakes && (
                    <Text as="h2" textAlign="center" color="white" paddingX={4}>
                      {t('assessViewer.you_have_reached_max_attempts')}
                    </Text>
                  ))}

                {assessment.allow_retake &&
                  userAssessment.scores.length - 1 < assessment.number_of_retakes && (
                    <VStack>
                      <Text as="h2" textAlign="center" color="white" paddingX={4}>
                        <Trans i18nKey="assessViewer.oops_did_not_pass" />
                      </Text>

                      <Button rightIcon={<Redo />} onClick={() => setRetakingAssessment(true)}>
                        {t('assessViewer.try_again')}
                      </Button>
                    </VStack>
                  )}
              </>
            )}

            {!inTrack && !inJourney && (
              <Button
                variant="ghost"
                color="white"
                rightIcon={<ArrowRight />}
                onClick={() => navigate('/explore')}
              >
                {t('assessViewer.explore_content')}
              </Button>
            )}

            {inJourney && (
              <Button
                variant="ghost"
                color="white"
                rightIcon={<ArrowRight />}
                onClick={backToJourney}
              >
                {t('global.action.backToJourney')}
              </Button>
            )}
          </VStack>
        </Center>

        {((hasPassed && assessment.show_user_correct) ||
          (hasPassed && assessment.file_assessment) ||
          (hasPassed && assessment.video_assessment) ||
          (!hasPassed && assessment.show_user_correct_after_fail)) && (
          <Container
            padding={12}
            maxWidth="container.md"
            backgroundColor="white"
            marginTop={8}
            boxShadow="sm"
          >
            {(assessment.video_assessment || assessment.file_assessment) && (
              <>
                <Text as="h5">{assessmentScores.assessment_when_scored.questions[0].question}</Text>

                <Grid
                  templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                  }}
                  gap={4}
                >
                  <GridItem>
                    <Box maxWidth="100vw">
                      {assessment.video_assessment && (
                        <VideoPlayer
                          url={userAnswer0 as string}
                          setRenderRecommendations={() => {}}
                        />
                      )}

                      {assessmentUsesFileAnswers(userAnswer0, assessmentScores) && (
                        <>
                          {isOther(userAnswer0) && (
                            <UnorderedList>
                              <ListItem>
                                <Link href={userAnswer0.link} download={userAnswer0.title}>
                                  <Download marginRight={2} />

                                  {userAnswer0.title}
                                </Link>
                              </ListItem>
                            </UnorderedList>
                          )}

                          {isPdf(userAnswer0) && (
                            <DocViewer
                              pluginRenderers={DocViewerRenderers}
                              documents={[
                                {
                                  uri: userAnswer0.link,
                                },
                              ]}
                              config={{
                                header: {
                                  disableFileName: true,
                                },
                              }}
                            />
                          )}

                          {isImage(userAnswer0) && <Image src={userAnswer0.link} />}
                        </>
                      )}
                    </Box>
                  </GridItem>

                  <GridItem backgroundColor="blackAlpha.50">
                    <VStack paddingY={12} paddingX={8}>
                      {assessmentScores.assessment_when_scored.questions[0].rubric_options.map(
                        (item) => {
                          const gradedResponse = userResponses[assessment.questions[0].id];
                          if (!gradedResponse.rubric_scores) {
                            return;
                          }
                          const rubricScore = gradedResponse.rubric_scores[item.id];
                          const rubricPointValue = gradedResponse.rubric_point_value[item.id];
                          const feedback =
                            gradedResponse.feedback && gradedResponse.feedback[item.id]
                              ? gradedResponse.feedback[item.id]
                              : '';

                          return (
                            <Box
                              key={item._id}
                              width="full"
                              textAlign="center"
                              borderTop="1px"
                              borderColor="blackAlpha.300"
                              paddingY={6}
                            >
                              <Text fontWeight="bold">{item.name}</Text>

                              <Text fontSize="xs">
                                {t('assessViewer.youScored')}{' '}
                                <Text as="span" color="brand.primary">
                                  {rubricScore}
                                </Text>{' '}
                                {t('assessViewer.outOf')}{' '}
                                <Text as="span" color="brand.primary">
                                  {rubricPointValue}
                                </Text>{' '}
                                {t('assessViewer.points')}
                              </Text>

                              <Text color="brand.primary" marginTop={4}>
                                {feedback}
                              </Text>
                            </Box>
                          );
                        },
                      )}
                    </VStack>
                  </GridItem>
                </Grid>
              </>
            )}

            {!assessment.video_assessment && !assessment.file_assessment && (
              <>
                {assessmentQuestions.map((question: any) => {
                  let copy = '';
                  const copyArr = [];

                  if (question.format === 'multiple_choice' || question.format === 'dropdown') {
                    const userAnswerIds =
                      userResponses[question.id as keyof typeof userResponses].user_answer;

                    userAnswerIds.forEach((id) => {
                      copyArr.push(
                        question.choice_options.find((option: any) => option.id === id).copy,
                      );
                    });
                  } else if (
                    question.format === 'short_input' ||
                    question.format === 'long_input'
                  ) {
                    copy = userResponses[question.id as keyof typeof userResponses]
                      .user_answer[0] as string;

                    copyArr.push(copy);
                  }

                  const { feedback } = userResponses[question.id];

                  const correctAnswers = question.choice_options.filter(
                    (option: any) => option.points,
                  );

                  return (
                    <Flex key={question._id} padding={6} borderBottom="1px" borderColor="gray.300">
                      <Stack width="65%">
                        <Text as="b">{question.question}</Text>

                        {copyArr.map((copyItem) => (
                          <Text key={copyItem} fontSize="sm">
                            {copyItem}
                          </Text>
                        ))}

                        {feedback && (
                          <Text as="i" fontSize="sm" color="brand.primary">
                            "{feedback.toString()}"
                          </Text>
                        )}
                      </Stack>

                      {question.format !== 'long_input' && (
                        <Stack backgroundColor="#f7f7f7" width="35%" padding={4}>
                          <VStack>
                            <Text fontSize="sm" fontWeight="bold">
                              {correctAnswers.length > 1
                                ? t('questionAnswerPresenter.all_accepted_answers')
                                : t('questionAnswerPresenter.correct_answer')}
                            </Text>

                            {correctAnswers.map((answer: any) => (
                              <Text key={answer.copy} fontSize="xs">
                                {answer.copy}{' '}
                                {correctAnswers.length > 1 && (
                                  <Text as="span" color="brand.primary">
                                    {answer.points} {t('edit.assessment.points')}
                                  </Text>
                                )}
                              </Text>
                            ))}
                          </VStack>
                        </Stack>
                      )}
                    </Flex>
                  );
                })}
              </>
            )}
          </Container>
        )}
      </Box>

      {viewingCertificate && (
        <Certificate
          contentTitle={assessment.title}
          completedDate={userAssessment.date_passed}
          userFullName={user.full_name}
          userMetadata={user.metadata}
          contentMetadata={assessment.metadata as Record<string, string>}
          certificateData={assessment.certificate_data}
          onClose={() => setViewingCertificate(false)}
        />
      )}
    </>
  );
}

AssessmentScore.defaultProps = {
  inTrack: false,
  inJourney: false,
  backToJourney: null,
};
